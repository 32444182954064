/*
  npm dependencies *must* be imported as (less), otherwise @imports to
  npm:// will be generated for the browser.
*/
/* Start @ak-color-* overrides  */
/* End @ak-color-* overrides  */
/* navigation dark theme */
/* subtle buttons */
/* light buttons */
/* primary buttons */
/* danger buttons */
/* warning buttons */
/* link buttons */
/* aui-select2 */
/* aui-dialog */
/* aui-tabs */
/* aui-lozenge */
/* aui inline-dialog */
/* aui messages */
/* reftags */
/* tables */
body:not(.aui-page-sidebar-touch) .repo-sidebar .aui-sidebar-wrapper {
  height: 1440px;
}
.repo-sidebar .aui-iconfont-locked {
  height: 12px;
  vertical-align: text-bottom;
  width: 12px;
}
.repo-sidebar .aui-iconfont-locked::before {
  color: var(--ds-text-subtlest, var(--ds-background-accent-gray-subtle-hovered, #505F79));
  font-size: 12px;
}
.repo-sidebar .entity-name {
  color: var(--ds-text, #172B4D);
}
.repo-sidebar .repository-settings .aui-nav-heading {
  padding-top: 0;
}
#repo-actions {
  /* fixes wonky vertical alignment of flyout */
}
#repo-actions.aui-expander-content {
  /* Roughly the height of 4 nav items to cut off after the "More" link.
        No idea if this value is correct. */
  min-height: 9.5em;
}
.aui-inline-dialog .aui-sidebar-submenu #repo-actions.aui-expander-content {
  /* Roughly the height of 5 nav items to make sure everything is displayed */
  min-height: 11.875em;
}
#repo-actions.aui-nav > li > a {
  line-height: 1.42857143;
}

/*# sourceMappingURL=../../css/components/repo-sidebar.css.map */
