/*
  npm dependencies *must* be imported as (less), otherwise @imports to
  npm:// will be generated for the browser.
*/
/* Start @ak-color-* overrides  */
/* End @ak-color-* overrides  */
/* navigation dark theme */
/* subtle buttons */
/* light buttons */
/* primary buttons */
/* danger buttons */
/* warning buttons */
/* link buttons */
/* aui-select2 */
/* aui-dialog */
/* aui-tabs */
/* aui-lozenge */
/* aui inline-dialog */
/* aui messages */
/* reftags */
/* tables */
/*
  npm dependencies *must* be imported as (less), otherwise @imports to
  npm:// will be generated for the browser.
*/
body.adg3 {
  /* General overrides. */
}
/* skate elements */
.aui-dropdown2:not([resolved]) {
  display: none;
}
.aui-popup.bb-dialog {
  height: auto !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--ds-background-input-hovered, #F4F5F7);
}
.aui-popup.bb-dialog .dialog-panel-body {
  height: auto !important;
  margin-top: 1px;
  max-height: calc(100vh - 200px);
}
#commit-form {
  overflow: visible;
}
#commit-form .dialog-panel-body {
  overflow: visible;
}
/* Buttons */
.aui-button {
  /* Dropdown in buttons */
  /*
   * AUI styles all <a> inside dropdown2 as inactive
   * Since ours is not a traditional list, we have to unfortunately
   * reimplement the styles for aui-buttons.
   */
  /* Fixes bug where content left of disabled button jumps left 1px on hover */
  /* Fixes alignment of ellipsis inside a subtle button */
}
.aui-buttons .aui-button ~ .aui-button.loading:hover {
  position: static;
}
.aui-button .aui-icon-dropdown {
  opacity: 0.8;
  top: 2px;
}
.aui-button:hover .aui-icon-dropdown {
  opacity: 1;
}
.aui-dropdown2.aui-style-default:hover .aui-button {
  color: var(--ds-text, #172B4D);
  background-color: var(--ds-background-input-hovered, #F4F5F7);
}
.aui-dropdown2.aui-style-default:hover .aui-button:hover {
  color: var(--ds-text, #000);
  background-color: var(--ds-surface-hovered, #EBECF0);
}
.aui-dropdown2.aui-style-default:hover .aui-button:active {
  background-color: var(--ds-background-input-hovered, #F4F5F7);
}
.aui-dropdown2.aui-style-default:hover .aui-button.aui-button-primary {
  color: var(--ds-text-inverse, #fff);
  background-color: var(--ds-link, #3572b0);
}
.aui-dropdown2.aui-style-default:hover .aui-button.aui-button-primary:hover {
  background-color: var(--ds-background-brand-bold-hovered, #2a67a5);
}
.aui-dropdown2.aui-style-default:hover .aui-button.aui-button-primary:active {
  background-color: var(--ds-link, #3572b0);
}
.aui-buttons .aui-button ~ .aui-button.aui-button-subtle[disabled]:hover,
.aui-buttons .aui-button ~ .aui-button.aui-button-subtle[aria-disabled='true']:hover {
  margin-left: 0;
}
.aui-button.aui-button-subtle .aui-iconfont-more:only-child {
  margin-right: 0;
  margin-top: 0;
}
/* Fixes styling of light subtle buttons */
.aui-button-subtle.aui-button-light,
a.aui-button-subtle.aui-button-light {
  background-color: transparent;
}
.aui-button-subtle.aui-button-light:hover,
a.aui-button-subtle.aui-button-light:hover {
  background: var(--ds-surface, var(--ds-text-inverse, #fff));
}
.aui-button-subtle.aui-button-light:hover[disabled],
a.aui-button-subtle.aui-button-light:hover[disabled] {
  background-color: transparent;
}
/* Fixes layout of multiple button groups in page header */
.aui-page-header-actions > .aui-buttons + .aui-buttons {
  margin-left: 10px;
}
/* Fixes oversight in AUI that doesn't apply this style to anchor tags */
a.aui-button-split-main {
  border-right: 0;
}
/* Horizontal nav */
.aui-navgroup-horizontal-roomy.aui-navgroup-horizontal .aui-nav a {
  padding-left: 20px;
  padding-right: 20px;
}
/* Typography */
h1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.25;
  margin: 30px 0 0;
}
h2,
section h1 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  margin: 30px 0 0;
}
h3,
section h2,
section section h1 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5625;
  margin: 30px 0 0;
}
h4,
section h3,
section section h2,
section section section h1 {
  color: var(--ds-text, #172B4D);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.42857143;
  margin: 20px 0 0;
}
h5,
section h4,
section section h3,
section section section h2,
section section section section h1 {
  color: var(--ds-text-subtlest, var(--ds-background-accent-gray-subtle-hovered, #505F79));
  font-size: 12px;
  font-weight: bold;
  line-height: 1.66666667;
  margin: 20px 0 0;
  text-transform: uppercase;
}
h6,
section h5,
section section h4,
section section section h3,
section section section section h2,
section section section section section h1 {
  color: var(--ds-text-subtlest, var(--ds-background-accent-gray-subtle-hovered, #505F79));
  font-size: 12px;
  font-weight: bold;
  line-height: 1.66666667;
  margin: 20px 0 0;
  text-transform: inherit;
}
h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
  margin-top: 10px;
}
/* Platform notifications popup list */
div.atlaskit-portal div[aria-labelledby='notification-list-header-label'] article h3 {
  text-transform: none;
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
div.atlaskit-portal div[aria-labelledby='notification-list-header-label'] article h3 span {
  color: var(--ds-text-subtlest, #6b778c);
  font-weight: 400;
}
/* Forms */
form.aui {
  /* Copy disabled styles for readonly */
  /* Better spacing for inline messages in forms */
}
form.aui .field-value {
  font-weight: normal;
}
form.aui .field-group .warning,
form.aui .checkbox .warning,
form.aui .radio .warning {
  clear: both;
  color: var(--ds-background-warning-bold, #e8a317);
  display: block;
  margin: 5px 0 0;
}
form.aui input.text.has-errors {
  border-color: var(--ds-border-accent-red, #d04437);
}
form.aui .text[readonly] {
  background-color: var(--ds-background-input-hovered, #F4F5F7);
  color: var(--ds-text-disabled, var(--ds-text-subtlest, #97A0AF));
}
form.aui .aui-message {
  margin: 10px 0;
}
form.aui.dialog-form {
  margin-bottom: 20px;
}
form.aui.modal-dialog-form {
  margin-top: 16px;
}
/* "Floating" messages */
#aui-message-bar {
  left: 50%;
  margin-left: -300px;
  position: fixed;
  top: 20px;
  width: 600px;
  /* Make sure messages show up over blanket (2500) and dialog (3006) */
  z-index: 4000;
}
#aui-message-bar .aui-message.shadowed {
  box-shadow: 0 3px 6px var(--ds-border, rgba(0, 0, 0, 0.13));
}
/* Inline Dialog */
.aui-inline-dialog .aui-inline-dialog-contents {
  /* Remove default padding (required for clone dialog, among others) */
  padding: 0;
  /* loading state for inline dialog */
}
.aui-inline-dialog .aui-inline-dialog-contents.loading {
  background-image: url(../img/loading.gif);
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 50px;
}
/*
  Disabled button styles are overridden by dialog button styles in AUI, so
  we're reproducing them here
*/
.aui-dialog .dialog-button-panel .aui-button[disabled],
.aui-dialog .dialog-button-panel .aui-button[disabled]:hover,
.aui-dialog .dialog-button-panel .aui-button[disabled]:focus,
.aui-dialog .dialog-button-panel .aui-button[disabled]:active,
.aui-dialog .dialog-button-panel .aui-button[aria-disabled='true'],
.aui-dialog .dialog-button-panel .aui-button[aria-disabled='true']:hover,
.aui-dialog .dialog-button-panel .aui-button[aria-disabled='true']:focus,
.aui-dialog .dialog-button-panel .aui-button[aria-disabled='true']:active,
.aui-dialog .dialog-button-panel .aui-button[aria-disabled='true'][aria-pressed],
.aui-dialog .dialog-button-panel .button-panel-button[disabled],
.aui-dialog .dialog-button-panel .button-panel-button[disabled]:hover,
.aui-dialog .dialog-button-panel .button-panel-button[disabled]:focus,
.aui-dialog .dialog-button-panel .button-panel-button[disabled]:active,
.aui-dialog .dialog-button-panel .button-panel-button[aria-disabled='true'],
.aui-dialog .dialog-button-panel .button-panel-button[aria-disabled='true']:hover,
.aui-dialog .dialog-button-panel .button-panel-button[aria-disabled='true']:focus,
.aui-dialog .dialog-button-panel .button-panel-button[aria-disabled='true']:active {
  background-color: var(--ds-text-inverse, #fff);
  background-image: linear-gradient(to bottom, var(--ds-text-inverse, #fff), var(--ds-background-accent-gray-subtlest, #f2f2f2));
  background-repeat: repeat-x;
  border-color: var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
  box-shadow: none;
  color: var(--ds-text-disabled, var(--ds-text-subtlest, #97A0AF));
  cursor: default;
  text-shadow: none;
}
/* AUI has default pointer for tabs, which seems weird */
.aui-tabs.aui-tabs-disabled > .tabs-menu > .menu-item > a {
  cursor: pointer;
}
/* Use default cursor for disabled pagination items */
.aui-nav-pagination a[aria-disabled='true'] {
  cursor: default;
}
/* Update pagination component to look like the AK one */
body.adg3 .adg3-aui-nav-pagination {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 64px;
}
body.adg3 .adg3-aui-nav-pagination .aui-nav-selected,
body.adg3 .adg3-aui-nav-pagination a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  box-sizing: border-box;
  height: 32px;
  min-width: 32px;
  padding: 0 12px;
  font-weight: 400;
  text-decoration: none;
}
body.adg3 .adg3-aui-nav-pagination a {
  background: transparent;
  color: var(--ds-background-accent-gray-subtle, #42526E);
}
body.adg3 .adg3-aui-nav-pagination a:hover:not([aria-disabled='true']) {
  background: var(--ds-background-neutral-subtle-hovered, rgba(9, 30, 66, 0.08));
}
body.adg3 .adg3-aui-nav-pagination a:active:not([aria-disabled='true']) {
  color: var(--ds-text-information, #0052CC);
}
body.adg3 .adg3-aui-nav-pagination a[aria-disabled='true'] {
  cursor: not-allowed;
}
body.adg3 .adg3-aui-nav-pagination .aui-nav-selected {
  background: var(--ds-background-accent-gray-bolder-pressed, #253858);
  color: var(--ds-text-inverse, #FFFFFF);
}
body.adg3 .adg3-aui-nav-pagination .aui-nav-previous,
body.adg3 .adg3-aui-nav-pagination .aui-nav-next {
  position: relative;
  width: 32px;
}
body.adg3 .adg3-aui-nav-pagination #previous-page-link::before,
body.adg3 .adg3-aui-nav-pagination #next-page-link::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 14px;
  width: 6px;
  height: 6px;
  border: none;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(-135deg);
}
body.adg3 .adg3-aui-nav-pagination #next-page-link::before {
  transform: rotate(45deg);
  left: 10px;
}
/* Bonus aui badge style (maybe not ADG approved, but used in Jira) */
aui-badge[active='true'],
.aui-badge.aui-badge-active {
  background-color: var(--ds-background-accent-teal-bolder, #326ca6);
  color: var(--ds-text-inverse, #fff);
}
/*
  disabled input styles for auiSelect2
  https://ecosystem.atlassian.net/browse/AUI-1705
*/
.aui-select2-container.select2-container-disabled a.select2-choice {
  background: var(--ds-background-input-hovered, #F4F5F7);
  border-color: var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
  color: var(--ds-text-disabled, var(--ds-text-subtlest, #97A0AF));
  cursor: default;
}
.aui-select2-container.select2-container-disabled a.select2-choice:active,
.aui-select2-container.select2-container-disabled a.select2-choice:hover {
  background: var(--ds-background-input-hovered, #F4F5F7);
  border-color: var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
  color: var(--ds-text-disabled, var(--ds-text-subtlest, #97A0AF));
  box-shadow: none;
}
.aui-select2-container.select2-container-disabled a.select2-choice:hover::after {
  opacity: 0.8;
}
.aui-select2-container.select2-container-disabled a.select2-choice .select2-arrow {
  background: var(--ds-background-input-hovered, #F4F5F7);
}
/* Disabled option styles for Select2 */
.select2-results .select2-disabled {
  background: none;
}
.select2-results .select2-disabled .select2-result-label {
  color: var(--ds-text-disabled, var(--ds-text-subtlest, #97A0AF));
  font-size: inherit;
  font-style: italic;
}
.select2-results .select2-no-results {
  background: var(--ds-background-accent-gray-subtlest, "#f4f4f4");
}
/* Repo avatar positioning within a Select2 instance */
.aui-select2-container .repo-avatar-container,
.aui-select2-drop .repo-avatar-container {
  display: inline-block;
  margin: 2px 10px 0 0;
  vertical-align: top;
}
/* "Clear choice" icon for Select2 */
.aui-select2-container.select2-allowclear .select2-choice abbr {
  margin-top: 2px;
  display: block;
}
/* Make sure placeholder text for Select2 matches placeholder text for normal inputs */
.aui-select2-container .select2-default {
  color: var(--ds-text-subtle, var(--ds-background-accent-gray-subtle-hovered, #505F79)) !important;
  width: 100% !important;
}
.aui-select2-container.select2-dropdown-open .select2-default .select2-chosen {
  color: var(--ds-text-inverse, #fff) !important;
}
.select2-result-label .aui-avatar {
  margin-right: 5px;
}
/* Aui's float here causes problems with cutting off the placeholder text */
.select2-container-multi .select2-choices .select2-search-field {
  float: none;
}
/* Add some buffer to the bottom of all pages */
.aui-page-panel-content {
  padding-bottom: 60px;
}
/* Set a minimum height for pages; prevents the footer from being too close to the header */
#content .aui-page-panel,
#content .aui-page-panel-inner {
  min-height: 400px;
}
/* Fake being the first child of #content since we add some random stuff in between */
#content > .aui-page-panel-no-header {
  margin-top: 0;
}
/* Allows for fixed-width content in fluid-width layout, eg on the support page */
.aui-page-panel-fixed .aui-page-panel-inner {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}
.aui-page-panel-fixed .aui-page-panel-inner .aui-page-panel-content {
  padding-left: 0;
  padding-right: 0;
}
.aui-sidebar {
  /* 100 z-index is to leave more space beneath a sidebar,
     so if we have to add something under it we won't change z-index here. */
  z-index: 100;
}
.aui-sidebar .aui-sidebar-footer .aui-sidebar-toggle {
  /* Sidebar toggle should take up full width of footer since we've moved settings button */
  text-align: right;
  width: 100%;
}
/* Fix display of subtle button used as inline dialog trigger */
.aui-button-subtle.active {
  border-color: var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
}
/* Update select2 multi-choice layout to work better with avatars but
   maintain "normal" input height */
.aui-select2-container.select2-container-multi {
  /* Make sure locked multi Select2 choices look the same as unlocked choices */
}
form.aui .aui-select2-container.select2-container-multi .select2-choices {
  padding: 8px 8px 0;
  border: 2px solid var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
  cursor: pointer;
  background-color: var(--ds-background-input, #FAFBFC);
}
form.aui .aui-select2-container.select2-container-multi .select2-choices:hover {
  background-color: var(--ds-surface-hovered, #EBECF0);
}
.aui-select2-container.select2-container-multi .select2-choices .select2-search-choice {
  padding: 2px 24px 2px 8px;
  line-height: 20px;
  border-radius: 16px;
  border: none;
  margin: 0 8px 8px 0;
  background: var(--ds-background-input-hovered, #F4F5F7);
}
.aui-select2-container.select2-container-multi .select2-search-choice-close {
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  background-position: center !important;
}
.aui-select2-container.select2-container-multi .select2-search-field {
  padding: 2px;
  margin-bottom: 8px;
}
.aui-select2-container.select2-container-multi .aui-avatar {
  margin-right: 2px;
}
.aui-select2-container.select2-container-multi .select2-locked {
  padding: 2px 5px !important;
}
form.aui .aui-select2-container.select2-container-multi.select2-dropdown-open .select2-choices {
  border-radius: 3px;
}
/*
  We shouldn't be able to interact with hidden InlineDialog2's.

  Hidden dialogs have `display: block` set so transitions work on open/close,
  but then the dialog contents remain as part of the DOM. It's possible to
  interact with the hidden dialog in this state, which has something to do
  with our Connect iframe inside the dialog.
*/
aui-inline-dialog2[aria-hidden='true'] iframe {
  display: none;
}
/*
  AUI has built-in support for images in dropdown items, but it doesn't
  take into account `aui-avatar` elements (AUI-3589)
*/
.aui-dropdown2.aui-style-default .aui-icon-container > .aui-avatar {
  border-width: 0;
  left: 10px;
  position: absolute;
  top: 5px;
}
/* IE Overrides */
html.explorer form.aui .text,
html.explorer form.aui .password,
html.explorer form.aui .textarea,
html.explorer form.aui .select,
html.explorer form.aui .multi-select {
  max-width: inherit;
}
/* Provide predictable widths for inline dialogs */
.aui-inline-dialog-small {
  max-width: 400px;
}
.aui-inline-dialog-medium {
  max-width: 600px;
}
.aui-inline-dialog-large {
  max-width: 800px;
}
.aui-inline-dialog-xlarge {
  max-width: 980px;
}
/* Fix alignment of sidebar heading */
.aui-sidebar .aui-page-header-main > h1:only-child {
  margin-top: -4px;
}
.aui-sidebar-header-large .aui-page-header-main > h1:only-child {
  margin-top: 0;
}
/* Remove border on project avatars */
.aui-avatar-project {
  box-shadow: none;
}
.aui-avatar-project::before {
  border: none;
}
/* Update content containers to use rounded squares according to the ADG */
body.adg3 .aui-avatar-squared .aui-avatar-inner img {
  border-radius: 3px;
}
/* Use for workspace breadcrumbs icon */
body.adg3 .aui-avatar.aui-avatar-workspace-icon {
  margin-right: 2px;
  background-color: var(--ds-text-inverse, #fff);
  width: 16px;
  height: 16px;
}
body.adg3 .aui-avatar.aui-avatar-workspace-icon,
body.adg3 .aui-avatar.aui-avatar-workspace-icon > img {
  border-radius: 2px;
}
/* Allows for a "fullscreen" dialog2 (used by side-by-side diff) */
.aui-dialog2-fullscreen {
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);
  min-height: 200px;
}
.aui-dialog2-fullscreen.aui-layer {
  top: 100px;
}
.aui-dialog2-fullscreen .aui-dialog2-content {
  height: calc(100% - 100px);
}
/* AUI places an arbitrary 300px max width on dropdowns; we're placing a larger arbitrary max-width instead */
.aui-dropdown2 {
  max-width: 420px;
}
/* Set max-width to 'none' to align the width of the dropdown with the select field in the 'Add a member' dialog */
div.adg3-select-group-dropdown {
  max-width: none;
}
/* AUI doesn't capitalize these automatically(?) */
.aui-select2-drop .select2-result-unselectable > .select2-result-label {
  text-transform: uppercase;
}
/* Better differentiation of select2 result headings from result items */
.aui-select2-drop .select2-result-unselectable:not(:first-child) > .select2-result-label {
  border-top: 1px solid var(--ds-border, var(--ds-background-accent-gray-subtler, #DFE1E6));
  padding-bottom: 5px;
  padding-top: 7px;
}
form.aui .aui-select2-container {
  max-width: 250px;
}
/* actions in an AUI flag are supposed to have a &middot; as a divider between multiple actions (according to the ADG),
   but AUI css doesn't seem to apply this style correctly. Copied these properties from AK Flags */
.aui-flag .aui-nav-actions-list > li {
  margin-right: 0;
}
.aui-flag .aui-nav-actions-list > li:not(:first-child)::before {
  content: '·';
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 16px;
}
/* Overriding the header and footer styles in aui-adg package, for the new logos */
.aui-header {
  background-color: var(--ds-link-pressed, #0747A6);
}
.aui-header .aui-header-logo-bitbucket .aui-header-logo-device {
  background-image: url(../img/logos/bitbucket/bitbucket-white.svg);
}
#footer .footer-body {
  background: transparent;
}
#footer #footer-logo a,
#footer .footer-logo {
  background-image: url(../img/logos/atlassian-neutral.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 114px auto;
  height: 14px;
  width: 114px;
}
#footer .footer-logo {
  margin: 16px auto 0;
}
#footer #footer-logo a:hover {
  background-image: url(../img/logos/atlassian-blue.svg);
  background-size: 114px auto;
}

/*# sourceMappingURL=../css/aui-overrides.css.map */
