.flex-content {
  display: flex;
}
.flex-content--column {
  /* HACKY MAGIC HERE
   *
   * This magically makes the flex content expand to fill the column,
   * and it doesn't matter what the actual px value here is.
   */
  max-width: 1px;
}
.flex-content--primary {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex-content--secondary {
  flex: 0 0 auto;
}

/*# sourceMappingURL=../../css/components/flex-content.css.map */
